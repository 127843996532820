<template>
  <div class="app-container flex flex-v">
    <div class="searchBar bg-white" style="border: 1px solid white">
      <el-row>
        <el-col :span="8" :offset="1">
          <div class="fl title">公司名称</div>
          <div class="fl" style="width: 60%">
            <el-input v-model="searchForm.orgName" size="mini" placeholder="请输入企业名称"/>
          </div>
        </el-col>
        <el-col :span="4" :offset="2" style="float: right">
          <el-button size="mini" class="btnBackColor" type="primary" @click="initSearch({searchForm, currentPage:1})" icon="el-icon-search">查 询</el-button>
          <el-button size="mini" class="btnBackColor" type="primary" plain @click="create" icon="el-icon-plus">创 建</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="bg-white main-content-wrapper margin-top-10 flex flex-v flex-1" >
      <el-table
          height="6px"
          class="flex-1"
          header-cell-class-name="avue-crud"
          stripe
          ref="mytable"
          :data="search_result.list"
          size="mini"
      >
        <el-table-column label="公司名称" prop="orgName" align="center" min-width="180"/>
        <el-table-column label="社会统一信用代码" prop="unifiedSocialCreditCode" align="center" min-width="180"/>
        <el-table-column label="公司编码" prop="orgCode" align="center" min-width="180"></el-table-column>
        <el-table-column label="操作" align="center" min-width="140">
          <template slot-scope="scope">
            <el-button
                v-if="!scope.row.edit"
                size="mini"
                type="text"
                @click="rowEdit(scope.row)">
              编辑
            </el-button>
            <el-button
                v-if="!scope.row.edit"
                size="mini"
                type="text"
                @click="delRow(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页    -->
    <Pagination
        :page.sync="tablePage.currentPage"
        :total="tablePage.total"
        :limit.sync="tablePage.pageSize"
        @pagination="paginationChange"
    />
  </div>
</template>

<script>
import service from '@/api/company'
import Pagination from '@/components/Pagination/index'

export default {
  components: {Pagination},
  data() {
    return {
      search_result: {
        list: []
      },
      searchForm: {
        orgName: '',
        unifiedSocialCreditCode: '',
      },
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10
      },
    }
  },
  computed:{
    editButton(){
      let edit_button = false;
      if(this.$store.state.user.menuListAll){
        let obj = this.$store.state.user.menuListAll.find(f => f.code === 'hqt-company');
        edit_button =  obj && obj.children.length> 0 &&
                       obj.children.find(item => item.code === 'company_edit');
      }
      return edit_button;
    },
  },
  mounted() {
    this.initSearch({currentPage: 1});
  },
  methods: {
    initSearch({
       currentPage = this.tablePage.currentPage,
       pageSize = this.tablePage.pageSize,
    }) {
      this.tablePage.pageSize = pageSize;
      this.tablePage.currentPage = currentPage;
      let params = {
        ...this.searchForm,
        currentPage,
        pageSize,
      }
      service.getOrgList({...params}).then(resp => {
        if (resp.message === '成功') {
          this.search_result.list = resp.data.data;
          this.tablePage.total = resp.data.count;
        }
      })
    },
    paginationChange({page = 1, limit = 10}) {
      this.tablePage.currentPage = page
      this.tablePage.pageSize = limit
      this.initSearch({currentPage: page, pageSize: limit})
    },
    rowEdit(row) {
      this.$router.push({name: 'enterprise-info-edit', query: {id: row.id,isEdit: 'true'}});
    },
    delRow(row) {
      this.$confirm('确定要删除此项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          service.delOrg({id: row.id}).then(resp => {
            if(resp.success){
              this.$message.success('删除成功');
              this.initSearch({currentPage: 1});
            }
          })
      })
    },
    create() {
      this.$router.push({name: 'enterprise-info-edit', query: {isEdit: 'false'}});
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  background-color: #f6f6f6;
  height: 100%;
}

.fl {
  float: left
}

.fr {
  float: right
}

.clear {
  clear: both
}

.searchBar {
  padding: 30px;

  .title {
    line-height: 32px;
    margin-right: 10px;
    font-size: 12px;
  }
}

.btnBox {
  padding: 10px 20px;
  background: #eee;
  margin-left: -20px;
  margin-right: -20px;
}

.elTable .el-table__header tr
> > > .el-table__header th {
  padding: 15px;
  height: 30px !important;
  background-color: #f6f6f6;
  font-weight: bold;
}

> > > .el-table__header-wrapper .el-checkbox {
  right: 4px;
}

</style>
