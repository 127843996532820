/**
 * 公司信息
 */
import {getRequest,postRequest,putRequest,deleteRequest} from "@/utils/request_utils"

//详情
export default {
  getCompanyDetail:(params) =>{
    return getRequest(`/api-base/org/info`,{params});
  },
  saveOrg: (data) => {
    return postRequest(`/api-base/org/save`, {data})
  },
  getOrgList:(params) => {
    return getRequest(`/api-base/org/pageList`,{params});
  },
  getBank: (params) => {
    return getRequest(`/api-base/org/selectBank`, {params});
  },
  getBankTo: (data) => {
    return postRequest(`/api-base/ttradebanktypecbs/selTradeBankTypeCbsNoPage`, {data})
  },
  //删除出账公司
  delOrg: (data) => {
    return postRequest(`/api-base/org/delete`, {data})
  },
  //客商详情
  getCustomerInfo:(params) => {
    return getRequest(`/api-base/foreign/info`, {params})
  },
  // 获取客商编码
  getMerchantsCode:(data) => {
    return postRequest(`/api-base/foreign/code`, {data})
  },

}
